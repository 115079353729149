import React, { FC, useEffect, useMemo, useState } from 'react';

import { navigationRoutes } from '@app/utils/navigation-routes';
import TimeDuration from '@app/pages/analytics/project-records/blocks/time-duration';
import { Paginator, Skeleton } from '@ui';
import { FilterTableData, TableItem } from '@app/components/table/table.type';
import useTranslation from '@app/hooks/use-translation';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import { DownloadCloudIcon, Oscilogram } from '@app/components/ui/icons/icons-list';
import { Filter, Range } from '@app/components/ui/easy-filter/types';
import { useGetLiveReportRecordsQuery } from '@app/store/api/live-reports.api';
import { useLazyDownloadRecordQuery } from '@app/store/api/records.api';
import { useAppSelector } from '@app/store/store';
import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';
import { ChartsLayoutType } from '@app/interfaces/dashboards.type';
import Table from '@app/components/table';
import { MetricHeaderItem, MetricMetricItemData } from '@app/interfaces/metric.type';
import { useLazyGetProjectMetricQuery } from '@app/store/api/metrics.api';
import MetricTableValueBuild from '@app/components/metric-table-value-build';
import { useOutletContext } from 'react-router';

import { useGetChatLiveReportRecordsQuery } from '@app/store/api/chat-api/live-reports.api';

import { useLazyGetChatProjectMetricQuery } from '@app/store/api/chat-api/chat-metric.api';

import { ReportOutletContext } from '../report.outlet';

const DEFAULT_REPORT_RECORDS_LIMIT = 10;
const DEFAULT_REPORT_RECORDS_OFFSET = 0;

type ReportRecordsTableBlockProps = {
  filter: Filter & Range;
};

const ReportRecordsTableBlock: FC<ReportRecordsTableBlockProps> = (props) => {
  const { filter } = props;
  const { t } = useTranslation('pages.reports');
  const { liveReport, currentTemplate } = useOutletContext<ReportOutletContext>();
  const reportId = liveReport?.live_report_id || '';
  const { userSettings } = useAppSelector((state) => state.userSettings);

  const [reportRecordsLimit, changeReportRecordsLimit] = useState<number>(
    userSettings?.tablesLimit?.reports?.reportRecords || DEFAULT_REPORT_RECORDS_LIMIT,
  );
  const [metricSettings, updateMetricSettings] = useState<{
    [metric_id: string]: MetricHeaderItem;
  }>({});
  const [reportRecordsOffset, changeReportRecordsOffset] = useState<number>(
    DEFAULT_REPORT_RECORDS_OFFSET,
  );
  const [reportRecordsOrderBy, changeRecordsReportOrderBy] = useState<FilterTableData>();

  // api
  const [updateUserSettings, { isLoading: userLoading }] = useUpdateUserSettingsMutation();
  const { data: voiceLiveReportRecords, isLoading: voiceRecordsLoading } =
    useGetLiveReportRecordsQuery(
      {
        params: { id: reportId },
        body: {
          offset: reportRecordsOffset,
          limit: reportRecordsLimit,
          filter,
          ...reportRecordsOrderBy,
        },
      },
      { skip: currentTemplate === 'chat' || !reportId },
    );
  const { data: chatLiveReportRecords, isLoading: chatRecordsLoading } =
    useGetChatLiveReportRecordsQuery(
      {
        params: { id: reportId },
        body: {
          offset: reportRecordsOffset,
          limit: reportRecordsLimit,
          filter,
          ...reportRecordsOrderBy,
        },
      },
      { skip: currentTemplate === 'voice' || !reportId },
    );
  const recordsLoading = voiceRecordsLoading || chatRecordsLoading;
  const liveReportRecords = useMemo(
    () => ({
      chat: chatLiveReportRecords,
      voice: voiceLiveReportRecords,
    }),
    [chatLiveReportRecords, voiceLiveReportRecords],
  );
  const liveReportRecordsArrayForMap = useMemo(
    () => ({
      chat: chatLiveReportRecords?.chats,
      voice: voiceLiveReportRecords?.records,
    }),
    [chatLiveReportRecords, voiceLiveReportRecords],
  );

  const [getProjectMetricVoice, { isLoading: metricLoadingVoice }] = useLazyGetProjectMetricQuery();
  const [getProjectMetricChat, { isLoading: metricLoadingChat }] =
    useLazyGetChatProjectMetricQuery();
  const metricLoading = metricLoadingVoice || metricLoadingChat;
  const getProjectMetric = useMemo(
    () => ({ chat: getProjectMetricChat, voice: getProjectMetricVoice }),
    [getProjectMetricChat, getProjectMetricVoice],
  );
  const [downloadRecord] = useLazyDownloadRecordQuery();

  // api

  const [userSettingsLoading, setUserSettingsLoading] = useState(false);
  useEffect(() => {
    if (userLoading) {
      setUserSettingsLoading(true);
    }
    if (!userLoading) {
      setTimeout(() => setUserSettingsLoading(false), 300);
    }
  }, [userLoading]);

  useEffect(() => {
    changeReportRecordsLimit(userSettings?.tablesLimit?.reports?.reportRecords || 10);
  }, [userSettings?.tablesLimit?.reports?.reportRecords]);

  useEffect(() => {
    if (!liveReportRecords) return;
    if ((liveReportRecords[currentTemplate || 'voice']?.total || 0) <= reportRecordsLimit) {
      changeReportRecordsOffset(0);
    }
  }, [currentTemplate, liveReportRecords, reportRecordsLimit]);

  useEffect(() => {
    if (!liveReportRecords[currentTemplate || 'voice'] || !liveReport) return;

    updateMetricSettings(
      liveReportRecords[currentTemplate || 'voice']?.headers.reduce(
        (reportHeaderResult, currentMetric) => {
          reportHeaderResult[currentMetric.metric_id] = currentMetric;
          if (currentMetric.type === 'tags') {
            getProjectMetric[currentTemplate || 'voice']({
              project_id: liveReport?.project.project_id,
              metric_id: currentMetric.metric_id,
            })
              .unwrap()
              .then((metricInfoResult) =>
                updateMetricSettings((prev) => ({
                  ...prev,
                  [currentMetric.metric_id]: {
                    ...prev[currentMetric.metric_id],
                    settings: metricInfoResult.settings,
                  },
                })),
              );
          }
          return reportHeaderResult;
        },
        {},
      ) || {},
    );
  }, [currentTemplate, getProjectMetric, liveReport, liveReportRecords]);

  const checkingForSorting = (index) => {
    if (!liveReportRecords) return;
    return index !== (liveReportRecords[currentTemplate || 'voice']?.headers.length || 0) + 1;
  };

  const recordsLiveReportDataColumns = [
    {
      index: 'duration',
      title: t('records_table.duration'),
      size: 160,
      hintTitle: t('popup_hints.to_record_watch'),
    },
    ...(liveReportRecords[currentTemplate || 'voice']?.headers.map((titleData, index) => ({
      index: titleData.metric_id,
      title: titleData.name,
      size: 190,
      filter: checkingForSorting(index),
      disableDefaultHeight: titleData.type === 'tags',
    })) || []),
    { index: 'utils', title: t('records_table.actions'), size: 110 },
  ];
  // function displayItemVisualization(item) {
  //   if (item.visualization) {
  //     if (item.visualization === 'score5') {
  //       if (Number(item.value) > 5 || Number(item.value) < 0) {
  //         return item.value;
  //       } else {
  //         return (
  //           <div className="w-full flex items-center justify-start px-[10px]">
  //             <div
  //               className={`${getMetricScoreStyles(
  //                 Number(item.value),
  //               )} h-[23px] text-[15px] text-white font-[400] leading-[18px] flex items-center p-[3px_9px_2px_0px] justify-end rounded-[2px]`}
  //             >
  //               {item.value}
  //             </div>
  //           </div>
  //         );
  //       }
  //     } else if (item.visualization === 'score100') {
  //       if (Number(item.value) > 100 || Number(item.value) < 0) {
  //         return item.value;
  //       } else {
  //         return (
  //           <div className="w-full flex items-center justify-start px-[10px]">
  //             <div
  //               style={{ width: `${30 + Math.ceil(Number(item.value)) * 1.5}px` }}
  //               className={`${getMetricScoreStyles100(
  //                 Number(item.value),
  //               )} h-[23px] text-[15px] text-white font-[400] leading-[18px] flex items-center p-[3px_9px_2px_0px] justify-end rounded-[2px]`}
  //             >
  //               {item.value}
  //             </div>
  //           </div>
  //         );
  //       }
  //     } else return item.value;
  //   } else return item.value;
  // }

  const idKeyByTemplate = useMemo(() => ({ chat: 'chat_id', voice: 'record_id' }), []);
  const recordPathByTemplate = useMemo(
    () => ({ chat: navigationRoutes.chatProjectRecords, voice: navigationRoutes.projectRecords }),
    [],
  );

  const formattedLiveReportRecordsTableData = liveReportRecordsArrayForMap[
    currentTemplate || 'voice'
  ]?.map((dataItem) => ({
    duration: (
      <div className="inline-flex items-center h-full mt-[8px]">
        <TimeDuration
          link={`/${recordPathByTemplate[currentTemplate]}/${liveReport?.project.project_id}/${
            dataItem[idKeyByTemplate[currentTemplate]]
          }`}
          duration={dataItem.duration}
        />
      </div>
    ),
    ...Object.fromEntries(
      dataItem.data.map((metric) => [
        [metric.metric_id],
        metricSettings && liveReport ? (
          <MetricTableValueBuild
            currentTemplate={currentTemplate}
            metric={metric as MetricMetricItemData}
            recordID={dataItem[idKeyByTemplate[currentTemplate]]}
            projectID={liveReport.project.project_id}
            metricHeader={metricSettings[metric.metric_id]}
          />
        ) : (
          <div></div>
        ),
      ]),
    ),
    utils: (
      <div className="flex w-full items-start pl-[19px] pr-[10px]">
        <DownloadCloudIcon
          onClick={() => {
            downloadRecord({ record_id: dataItem.record_id });
          }}
          hintTitle={t('popup_hints.download_record')}
          size={18}
          className={`mr-[18px] text-3color hover:text-action transition cursor-pointer`}
        />

        <Oscilogram
          onClick={() =>
            window.open(
              `/${recordPathByTemplate[currentTemplate]}/${liveReport?.project.project_id}/${
                dataItem[idKeyByTemplate[currentTemplate]]
              }`,
              '_blank',
            )
          }
          hintTitle={t('popup_hints.to_record_watch')}
          size={22}
          className={'text-3color hover:text-action cursor-pointer transition'}
        />
      </div>
    ),
  }));

  function onChangePagination({ offset, limit }: PaginationResponse) {
    changeReportRecordsOffset(offset);
    updateUserSettings({
      ...userSettings,
      tablesLimit: {
        ...userSettings?.tablesLimit,
        reports: { ...userSettings?.tablesLimit?.reports, reportRecords: limit },
      },
    });
    changeReportRecordsLimit(limit);
  }

  const [tableLayouts, changeTableLayouts] = useState(
    userSettings?.tableColsSize?.reports?.reportRecords,
  );

  useEffect(() => {
    const condition = userSettings?.tableColsSize?.reports?.reportRecords?.find(
      (layoutItem) => layoutItem.id === reportId,
    );
    if (!condition) {
      const systemArr = [
        ...(userSettings?.tableColsSize?.reports?.reportRecords || []),
        { id: reportId, layout: [] },
      ];
      updateUserSettings({
        ...userSettings,
        tableColsSize: {
          ...userSettings?.tableColsSize,
          reports: { ...userSettings?.tableColsSize?.reports, reportRecords: systemArr },
        },
      });
    } else {
      changeTableLayouts(userSettings?.tableColsSize?.reports?.reportRecords);
    }
  }, [reportId, updateUserSettings, userSettings]);

  function onChangeLayoutHandler(layout: ChartsLayoutType[]) {
    const currentChangedLayout = tableLayouts?.map((tablesLayout) => {
      if (tablesLayout.id === reportId) {
        return { id: tablesLayout.id, layout: layout.map((item) => ({ ...item, maxW: 24 })) };
      }
      return tablesLayout;
    });

    updateUserSettings({
      ...userSettings,
      tableColsSize: {
        ...userSettings?.tableColsSize,
        reports: { ...userSettings?.tableColsSize?.reports, reportRecords: currentChangedLayout },
      },
    });
  }

  if (recordsLoading || metricLoading) return <Skeleton height={360} />;
  return (
    <div className="relative z-50">
      {userSettingsLoading && <Skeleton height={800} className={'absolute z-[999] mt-6 top-0'} />}
      <Table
        columns={recordsLiveReportDataColumns}
        dataSource={formattedLiveReportRecordsTableData as unknown as Array<TableItem>}
        onFilter={changeRecordsReportOrderBy}
        isEmpty={t('system.empty_records')}
        layout={tableLayouts
          ?.find((layoutItem) => layoutItem.id === reportId)
          ?.layout.map((item) => ({ ...item, maxW: 16 }))}
        onLayoutChange={onChangeLayoutHandler}
      />
      <div className="my-[20px]">
        <Paginator
          page={Math.ceil(reportRecordsOffset / reportRecordsLimit) + 1}
          count={liveReportRecords[currentTemplate || 'voice']?.total || 0}
          limit={reportRecordsLimit}
          onChange={onChangePagination}
        />
      </div>
    </div>
  );
};

export default ReportRecordsTableBlock;
