module.exports = {
  title: 'Настройки',
  warning: 'Предупреждение',
  invalid_format: 'Неверный формат данных',
  navigation: {
    common_settings: 'Общие настройки',
    alias: 'Алиасы',
    recognition_rules: 'Правила распознавания',
    integration_settings: 'Интеграция',
    manual_recording_upload: 'Ручная загрузка записей',
    // messagesHistory: 'История информационных сообщений',
    messagesHistory: 'Сообщения',
    messagesManaging: 'Получатели рассылок',
    // messagesManaging: 'Управление получателями информационных сообщений',
  },
  manual_upload: {
    toast_error_upload: 'Ошибка отправки файла',
    toast_success: 'Все файлы успешно загружены',
    title: 'Укажите метаданные для загружаемых файлов',
    add_metadata: 'Выберите способ заполнения метаданных',
    fill_manual: 'Заполнить самостоятельно',
    or: 'или',
    save_button: 'Загрузить записи',
    reset_button: 'Сбросить',
    metadata: 'Метаданные',
    alias: 'Алиас',
    csv_template: 'Шаблон файла CSV для загрузки',
    type: 'Тип данных',
    params: 'Значение',
    close: 'Закрыть'
  },
  recog_rules: {
    table: {
      name: 'Название',
      rules: 'Условие отбора записей',
      asr_model_id: 'Модель распознавания',
      actions: 'Действия',
      status: 'Статус'
    },
    modal_label: 'Создать правило распознавания',
    modal_label_edit: 'Редактировать правило распознавания',
    modal_title_recognize: 'Распознать за период',
    modal_label_recognize: 'Название правила',
    recognition_rules_queue: 'Размер очереди распознавания ',
    from: 'от',
    to: 'до',
    add_to_recognition_queue: 'Добавить в очередь распознавания',
    recognition_rules: 'Правила распознавания',
    interval_error: 'Неверный интервал',
    recognition_model_default: 'Модель распознавания по умолчанию',
    recognition_rules_comment:
      'Комментарий. Все записи, которые не попадают в прочие условия отбора',
    add_recognition_rule: 'Добавить правило',
    form: {
      name: 'Название',
      placeholder_name: 'Название',
      filter_rules: 'Правила фильтра',
      recog_model: 'Модель распознавания',
      choose_placeholder: 'Выбрать...',
      button_label_delete: 'Удалить правило',
      enabled: 'Активность',
      on: 'Правило активно',
      off: 'Правило не активно'
    },
    billing: {
      billing_this_month: 'Записей обработано в текущем календарном месяце: ',
      billing_prev_month: 'Записей обработано в предыдущем календарном месяце: ',
      records: ' записей, ',
      minutes: ' мин. '
    }
  },
  integrations:{
    integrations_title: 'Интеграции',
    add_integration: 'Добавить интеграцию',
    delete_integration_text: 'Вы действительно хотите удалить данную интеграцию?',
    table: {
      name: 'Название  Интеграции',
      description: 'Описание интеграции',
      type: 'Тип интеграции',
      actions: 'Действия',
      restapi: 'Загрузка записей REST',
      integrationapi: 'Интеграционный API'
    },
    modal_title: {
      create: 'Создание интеграции REST API',
      edit: 'Настройка интеграции REST API',
    },
    form: {
      name: 'Название',
      type: 'Тип интеграции',
      type2: 'Тип интеграции: ',
      saveButton: 'Сохранить',
      newKeysButton: 'Создать новую пару ключей',
      deleteButton: 'Удалить интеграцию',
      types: {
        rest: 'Загрузка записей REST',
        integrationapi: 'Интеграционный API',
      },
    },
    createdIntModalLabel: 'Генерация связки ключей',
    createdIntModalKey: 'Cкопируйте данный ключ доступа.',
    createdIntModalDescription: 'После закрытия данного окна ключ нельзя будет увидеть повторно.',
  },
  common_setting: {
    table: {
      name: 'Название',
      channel: 'Номер дорожки',
      actions: 'Действия'
    },
    delete_channel_text: 'Вы действительно хотите удалить данный канал?',
    form: {
      icon: 'Иконка',
      operator: 'Оператор',
      client: 'Клиент',
      other: 'Другой',
      channel_name: 'Название канала',
      channel_name_placeholder: 'Название',
      button_label: 'Сохранить',
      channel_number: 'Номер дорожки',
    },
    title: 'Аудио-каналы',
    modal_title: {
      edit: 'Настроить канал',
      create: 'Добавить канал',
    },
    button: {
      add_channel: 'Добавить канал',
    },
    chatRoles: {
      title: 'Роли чатов',
      add_Role: 'Добавить роль',
      alias: 'Алиас',
      modal: {
        title: 'Добавить роль',
        role_name: 'Название роли',
        role_name_palceholder: 'Роль',
        edit: 'Изменить роль',
        delete_role_text: 'Вы действительно хотите удалить данную роль?'
      },
      toast: {
        role_add: 'Роль успешно добавлена',
        role_edit: 'Роль успешно изменена',
        error_while_edit: 'Ошибка при изменении роли:',
        error_while_add: 'Ошибка при добавлении роли:'
      },
    }
  },
  alias: {
    add_alias_button: 'Добавить алиас',
    table: {
      name: 'Название',
      meta_name: 'Название метаданного',
      type_value: 'Тип значения',
      type_meta: 'Тип метаданного',
      utils: 'Действия',
    },
    popup_hints: {
      templates_list_count: 'Количество шаблонов в группе',
      delete: 'Удалить',
      setting: 'Настроить',
      recognize: 'Распознать за период',
      edit_model: 'Выбрать модель распознавания',
    },
    alias_title: 'Алиасы',
    delete_alias_text: 'Данное действие повлияет на работу системы',
    delete_alias_text_alias_is_using:
      'Данный алиас испольуется в других настройках системы и поэтому его нельзя удалить',
    modal_label: 'Создать алиас',
    modal_label_edit: 'Редактировать алиас',
    values_translate: {
      str: 'Текст',
      num: 'Число',
      system: 'Системный',
      base: 'Базовый',
      record: 'Запись',
      channel: 'Канал',
    },
    form: {
      alias: 'Алиас',
      meta: 'Метаданные',
      type_meta: 'Тип метаданного',
      type_value: 'Тип значения',
      text: 'Текст',
      number: 'Число',
      record_level: 'Уровни записей',
      channel_level: 'Уровни каналов',
      button_label: 'Сохранить',
      button_label_delete: 'Удалить алиас',
      placeholder_name: 'Название',
      placeholder_meta_name: 'Значение',
    },
  },
  messages_managing:{
    description: "Имя получателя",
    email: "E-mail",
    dt_add: "Дата добавления",
    confirmed: "Подтверждение",
    action: "Действия",
    subscribe: "Подписка",
    deleteRecipientText: 'Вы действительно хотите удалить этого получателя?',
    tg_template: 'Получатели сообщений Telegram',
    email_template: 'Получатели сообщений по E-mail',
    true: 'Верификация пройдена',
    false: 'Верификация не пройдена',
    add_recipient_button:"Добавить получателя"
  },
  messages_queue: {
    table: {
      dt_add: 'Дата и время сообщения',
      recipient_name: 'Получатель сообщения',
      status: 'Статус от системы доставки',
      priority: 'Приоритет'
    },
    NEW:'',
    FAIL: '',
    SUCCESS:'',
    TTL:'',
    reports: 'Отчеты',
    triggers:'Триггеры',
    system_messages: 'Системные сообщения',
    send_method: 'Метод отправки:',
    message_type: 'Тип сообщения:',

  }
};
