import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { GetFilterParamsNew } from '@app/components/ui/easy-filter/types';
import {
  ChatRecordsCombinedResponse,
  ChatRecordType,
  RecordBulkChats,
} from '@app/interfaces/record';

import {
  MetricRecordDataResult,
  MetricCasesWords,
  MetricListArrType,
} from '@app/interfaces/pages-types/anatylics-metric.type';

import {
  CalculatedRecordMetric,
  Dialog,
  KnownMetricsCases,
  KnownProjectMetric,
} from '@app/interfaces/records';

import { VoiceEmotion } from '@app/interfaces/record-transcription';

import { v4 } from 'uuid';

import { baseQuery } from '../../baseQuery';

export const allChatsApi = createApi({
  reducerPath: 'allChatsApi',
  baseQuery: baseQuery,
  tagTypes: ['chats'],
  endpoints: (build) => ({
    getChats: build.query<RecordBulkChats, GetFilterParamsNew>({
      query: (body) => ({
        url: serverRoutes.allChats,
        body,
        method: 'POST',
      }),
      providesTags: ['chats'],
    }),
    deleteChat: build.mutation<RecordBulkChats, { items: string[] }>({
      query: (body) => ({
        url: serverRoutes.singleChat,
        body,
        method: 'DELETE',
      }),
      invalidatesTags: ['chats'],
    }),
    getChatDetails: build.query<
      ChatRecordsCombinedResponse,
      { chat_id: string; project_id?: string }
    >({
      queryFn: async (arg, api, extraOptions, fetchWithBQ) => {
        const { chat_id, project_id } = arg;

        const chatDetails = (await fetchWithBQ({
          url: serverRoutes.singleChat,
          method: 'GET',
          params: { chat_id, project_id },
        })) as unknown as { data: ChatRecordType };

        const chatDialog = chatDetails.data.markup.reduce<Dialog[]>(
          (messageItem, currentMessage, index) => {
            const roleIndex = chatDetails.data.names.findIndex(
              (name) => name.userid === currentMessage.userid,
            );
            const currentMemberRole = chatDetails.data.names[roleIndex];
            const currentRoleIcon = chatDetails.data.chat_roles.find(
              (chatItem) => chatItem.crole_id === currentMemberRole?.croles[0],
            )?.icon;
            const messageId = `chat_message_${v4()}`;
            const dialogConfig: Dialog = {
              id: messageId,
              channel_id: currentMessage.userid,
              segment_id: index.toString(),
              channel_config: {
                settings_channels_id: currentMemberRole?.croles[0],
                name: currentMemberRole?.name,
                channel: roleIndex,
                icon: currentRoleIcon || '',
              },
              from: currentMessage.timestamp,
              to: currentMessage.timestamp,
              words: currentMessage.message_words.map((word, wordIndex) => ({
                segment_id: index.toString(),
                from: currentMessage.timestamp,
                to: currentMessage.timestamp,
                word_idx: `word_${currentMessage.userid}_${index}_${wordIndex}`,
                word: word,
                channel_id: String(roleIndex),
                emotion: { score: 1, tag: 'neutral' } as VoiceEmotion,
              })),
              emotion: { score: 1, tag: 'neutral' } as VoiceEmotion,
            };
            messageItem.push(dialogConfig);
            return messageItem;
          },
          [],
        );

        const data: ChatRecordsCombinedResponse = {
          chat: chatDetails.data,
          chatDialog: chatDialog,
        };

        if (project_id) {
          const metricRecordData = (
            await fetchWithBQ({
              url: serverRoutes.projectChatMetricResult,
              method: 'GET',
              params: { chat_id, project_id },
            })
          ).data as MetricRecordDataResult<MetricCasesWords>;

          const metricProjectData = (
            await fetchWithBQ({
              url: serverRoutes.metricChat,
              method: 'GET',
              params: { project_id },
            })
          ).data as MetricListArrType[];

          // metric data formatting
          const known_project_metrics_chat = metricProjectData?.reduce<Array<KnownProjectMetric>>(
            (acc, metric) => {
              acc.push({
                metric_id: metric.metric_id,
                name: metric.name,
                color: metric.settings?.color || '',
                type: metric.type,
                settings: metric.settings,
              });
              return acc;
            },
            [],
          );

          const metricValuesSortByMetricId =
            metricRecordData?.metrics_results?.metrics_values.reduce((acc, metricValue) => {
              acc[metricValue.metric_id] = metricValue.value;
              return acc;
            }, {});

          const known_metrics_cases_chat = known_project_metrics_chat.reduce<KnownMetricsCases>(
            (acc, known_metric) => {
              const cases =
                metricRecordData?.metrics_results?.metrics_cases[known_metric.metric_id];
              if (!cases) {
                return acc;
              }
              acc[known_metric.metric_id] = {
                known_metric: {
                  ...known_metric,
                  value: metricValuesSortByMetricId[known_metric.metric_id],
                },
                cases: Object.entries(cases).reduce((acc, [channel_id, segments]) => {
                  acc[channel_id] = Object.entries(segments).reduce(
                    (acc, [segment_id, metric_cases]) => {
                      const metricCaseCheckedToArray = Array.isArray(metric_cases)
                        ? metric_cases
                        : [metric_cases];
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      acc[segment_id] = metricCaseCheckedToArray.reduce((acc, metric_case) => {
                        for (let i = metric_case.from; i <= metric_case.to; i++) {
                          acc[i] = true;
                        }
                        return acc;
                      }, {});
                      return acc;
                    },
                    {},
                  );
                  return acc;
                }, {}),
              };
              return acc;
            },
            {},
          );

          const calculatedRecordMetric =
            metricRecordData.metrics_results.metrics_values.reduce<CalculatedRecordMetric>(
              (acc, metric) => {
                acc[metric.metric_id] = {
                  metric_id: metric.metric_id,
                  color:
                    metric.settings.type === 'c_spell-check' ? 'F06275' : metric.settings.color,
                  type: metric.settings.type,
                  name: metric.name,
                  value: metric.value || 0,
                  result_value_type: metricProjectData.find((i) => i.metric_id === metric.metric_id)
                    ?.result_value_type,
                  // TODO жесткие заглушки на запрет массивов и Undefined изменить при добавлении других метрик
                  settings:
                    !Array.isArray(
                      metricRecordData?.metrics_results?.metrics_cases[metric.metric_id],
                    ) && !!metricRecordData?.metrics_results?.metrics_cases[metric.metric_id]
                      ? Object.entries(
                          metricRecordData?.metrics_results.metrics_cases[metric.metric_id],
                        ).reduce<CalculatedRecordMetric[string]['settings']>(
                          (acc, [channelID, channelCase], index) => {
                            acc = Object.entries(channelCase).reduce(
                              (acc, [segmentIdD, segmentCase]) => {
                                const wordInfo: CalculatedRecordMetric[string]['settings'] = {
                                  ids: [],
                                  distance: [],
                                  messageId: [],
                                };
                                const wordIds = segmentCase.map(
                                  (word) => `word_${channelID}_${segmentIdD}_${word.from}`,
                                );
                                wordInfo['ids'] = wordIds;
                                wordInfo['distance'] = segmentCase.map(
                                  (word) => word.to - word.from + 1,
                                );
                                wordInfo['messageId'] = wordIds.map((word) => {
                                  return (
                                    chatDialog.find((dialog) =>
                                      dialog.words.find(
                                        (dialogWord) => dialogWord.word_idx === word,
                                      ),
                                    )?.id ||
                                    acc?.['messageId'][index - 1] ||
                                    undefined
                                  );
                                });

                                if (acc) {
                                  acc['ids'] = [...acc['ids'], ...wordInfo['ids']];
                                  acc['distance'] = [...acc['distance'], ...wordInfo['distance']];
                                  acc['messageId'] = [
                                    ...acc['messageId'],
                                    ...wordInfo['messageId'],
                                  ];
                                }
                                return acc;
                              },
                              acc,
                            );
                            return acc;
                          },
                          { ids: [], distance: [], messageId: [] },
                        )
                      : null,
                };
                return acc;
              },
              {},
            );
          // chat metric formatting
          data['known_project_metrics_chat'] = known_project_metrics_chat;
          data['calculatedRecordMetric'] = calculatedRecordMetric;
          data['known_metrics_cases_chat'] = known_metrics_cases_chat;
          data['metric'] = metricRecordData;
        }
        if (!chatDetails) return { error: { error: '123' } as FetchBaseQueryError };

        return { data };
      },
    }),
  }),
});

export const { useGetChatsQuery, useGetChatDetailsQuery, useDeleteChatMutation } = allChatsApi;
